import React, { useEffect, useState } from 'react';
import { Box, Divider, Flex, IconButton, Text } from '@chakra-ui/react';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
	FireIcon,
	GroupPeopleIcon,
	BoostEventsLogo,
	CloseNavBar,
	OpenNavBar,
} from '../icons/icons';
import Locale from '../../utils/translations';
import NavigationItem from './NavigationItem';
import { useLocation } from 'react-router-dom';

const SideBarNavigation: React.FC = () => {
	const [isLargerThan768, setIsLargerThan768] = useState(window.innerWidth >= 768);
	const [expandedNavBar, setExpandedNavBar] = useState(true);
	const location = useLocation();

	const translationSelected = location.pathname.includes('translation');
	const redactionSelected = location.pathname.includes('redaction');
	const llmSelected = location.pathname.includes('llm');
	const statisticsSelected = location.pathname.includes('statistics');
	const languageDetectionSelected = location.pathname.includes('language-detection');
	const speechToSpeechFlowSelected = location.pathname.includes('s2s-flow');

	useEffect(() => {
		const handleResize = () => {
			setIsLargerThan768(window.innerWidth >= 768);
			if (!isLargerThan768) {
				setExpandedNavBar(false);
			}
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [isLargerThan768]);

	useEffect(() => {
		const changeRouteOnSmallScreenHandler = () => {
			if (!isLargerThan768) {
				setExpandedNavBar(false);
			}
		};

		window.addEventListener('routeChangeComplete', changeRouteOnSmallScreenHandler);

		return () => {
			window.removeEventListener('routeChangeComplete', changeRouteOnSmallScreenHandler);
		};
	}, [isLargerThan768]);

	return (
		<Box
			as="aside"
			bg="white"
			h={{ sm: `${expandedNavBar ? '100vh' : '60px'}`, md: '100vh' }}
			w={{ sm: '100%', md: expandedNavBar ? '300px' : '76px' }}
			padding={{ sm: '0px', md: expandedNavBar ? '23px' : '23px 16px 23px 16px' }}
			position={{ sm: expandedNavBar ? 'absolute' : 'unset', md: 'unset' }}
		>
			<Flex
				flexDir={{ sm: `${expandedNavBar ? 'column' : 'row'}`, md: 'column' }}
				h={{ sm: expandedNavBar ? '100vh' : '100%', md: '100%' }}
				w="100%"
				bg="white"
				position={{ sm: `${expandedNavBar ? 'absolute' : 'relative'}`, md: 'relative' }}
				zIndex={10}
				p={{ sm: '23px', md: 'unset' }}
			>
				<Flex
					alignItems="center"
					justifyContent={{ sm: 'space-between', md: 'center' }}
					w="full"
				>
					<BoostEventsLogo mr={expandedNavBar ? '3' : 'unset'} fontSize="2em" />
					<Text
						fontWeight="700"
						display={expandedNavBar ? 'block' : 'none'}
						fontSize="18px"
					>
						Boostlingo
					</Text>
					<IconButton
						display={{ sm: 'block', md: 'none' }}
						variant="unstyled"
						onClick={() => setExpandedNavBar(!expandedNavBar)}
						aria-label="expand"
						icon={expandedNavBar ? <CloseIcon /> : <HamburgerIcon />}
					/>
				</Flex>
				<Flex flex={1} flexDir="column" overflowY="auto">
					<Box mt="23px">
						<Text
							display={expandedNavBar ? 'block' : 'none'}
							variant="beTextDescription"
						>
							{Locale.get('Links')}
						</Text>
						<NavigationItem
							title="Statistics"
							href="/statistics"
							onlyIcon={expandedNavBar}
							icon={
								<GroupPeopleIcon
									fill={statisticsSelected ? 'black' : '#707E8D'}
									mr={expandedNavBar ? '9px' : '0px'}
								/>
							}
							selected={statisticsSelected}
						/>
						<NavigationItem
							title="Translation"
							href="/translation"
							onlyIcon={expandedNavBar}
							icon={
								<FireIcon
									fill={translationSelected ? 'black' : '#707E8D'}
									mr={expandedNavBar ? '9px' : '0px'}
								/>
							}
							selected={translationSelected}
						/>
						<NavigationItem
							title="Redaction"
							href="/redaction"
							onlyIcon={expandedNavBar}
							icon={
								<FireIcon
									fill={redactionSelected ? 'black' : '#707E8D'}
									mr={expandedNavBar ? '9px' : '0px'}
								/>
							}
							selected={redactionSelected}
						/>
						<NavigationItem
							title="LLM"
							href="/llm"
							onlyIcon={expandedNavBar}
							icon={
								<GroupPeopleIcon
									fill={llmSelected ? 'black' : '#707E8D'}
									mr={expandedNavBar ? '9px' : '0px'}
								/>
							}
							selected={llmSelected}
						/>
						<NavigationItem
							title="Language Detection"
							href="/language-detection"
							onlyIcon={expandedNavBar}
							icon={
								<GroupPeopleIcon
									fill={languageDetectionSelected ? 'black' : '#707E8D'}
									mr={expandedNavBar ? '9px' : '0px'}
								/>
							}
							selected={languageDetectionSelected}
						/>
						<NavigationItem
							title="Speech to Speech Flow"
							href="/s2s-flow"
							onlyIcon={expandedNavBar}
							icon={
								<GroupPeopleIcon
									fill={speechToSpeechFlowSelected ? 'black' : '#707E8D'}
									mr={expandedNavBar ? '9px' : '0px'}
								/>
							}
							selected={speechToSpeechFlowSelected}
						/>
					</Box>
				</Flex>
				<Box display={{ sm: expandedNavBar ? 'block' : 'none', md: 'block' }}>
					<Divider mb="20px" />
					<IconButton
						aria-label="Open/Close Navigation Bar"
						mr="auto"
						ml="auto"
						mb="20px"
						display={{ sm: 'none', md: 'block' }}
						w="42px"
						onClick={() => setExpandedNavBar(!expandedNavBar)}
						icon={expandedNavBar ? <CloseNavBar /> : <OpenNavBar />}
					/>
				</Box>
			</Flex>
		</Box>
	);
};

export default SideBarNavigation;
